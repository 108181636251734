import React, { Fragment } from "react";
// import { graphql } from "gatsby";

import Layout from "../layout";
import { AuthUserContext } from "../Account/Session";

import { ROLES } from "../../constants";
import RecordAdmin from "./RecordAdmin";
import RecordView from "./RecordView";

const Record = ({ pageContext, location }) => {
  const record = pageContext.records[0].node;
  return (
    <Layout location={location}>
      <AuthUserContext.Consumer>
        {(authUser) => (
          <Fragment>
            {!!authUser?.roles[ROLES.ADMIN] ? (
              <RecordAdmin record={record} location={location} />
            ) : (
              <RecordView
                record={record}
                canEdit={!!authUser?.roles[ROLES.ADMIN]}
              />
            )}
          </Fragment>
        )}
      </AuthUserContext.Consumer>
    </Layout>
  );
};

export default Record;
